import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';

import { client } from './client';
import ProtectedRoute from './components/common/ProtectedRoute/ProtectedRoute';
import EventInvitePage from './pages/EventInvitePage/EventInvitePage';
import LandingPage from './pages/LandingPage/LandingPage';
import LoginPage from './pages/LoginPage/LoginPage';

const theme = createTheme(); // You can customize this theme as needed

// Define the shape of your environment variables
interface EnvVariables {
  REACT_APP_AUTH0_DOMAIN?: string;
  REACT_APP_AUTH0_CLIENT_ID?: string;
  REACT_APP_AUTH0_AUDIENCE?: string;
  REACT_APP_API_URL?: string;
}

// Safely access environment variables
const env: EnvVariables = {
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
};

// Ensure required variables are defined
if (!env.REACT_APP_AUTH0_DOMAIN || !env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error('Missing required environment variables for Auth0');
}

const Auth0Config = {
  domain: env.REACT_APP_AUTH0_DOMAIN,
  clientId: env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: env.REACT_APP_AUTH0_AUDIENCE,
  },
  redirectUri: window.location.origin,
};

const queryClient = new QueryClient();

// configure internal service client
client.setConfig({
  // set default base url for requests
  baseUrl: env.REACT_APP_API_URL,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Auth0Provider {...Auth0Config}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider />
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<ProtectedRoute element={<LandingPage />} />} />
              <Route path="/invite/:externalId" element={<EventInvitePage />} /> {/* Updated */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </Auth0Provider>
    </QueryClientProvider>
  );
}

export default App;
